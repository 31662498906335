






















































































































































































































import {Component, Vue} from "vue-property-decorator";
import {baseUrl, request} from "@/services/http";
import forgetPasswordBar from "@/components/forgetPasswordBar.vue";
import {clickReset, getVerificationCode, userRepassword,} from "@/services/api";
import {message} from "ant-design-vue";

@Component({
    components: {
        forgetPasswordBar
    }
})
export default class ForgetPasswordNew extends Vue {
    step = 1;
    username = "";
    usernameTip = "";

    identify = "";
    identifyTip = "";
    stepData = [
        {
            name: "输入账号名",
            time: "",
            img: require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "验证身份",
            time: "",
            img: require('@/assets/images/homeImg/flow-2.png'),
        },
        {
            name: "重置密码",
            time: "",
            img: require('@/assets/images/homeImg/flow-3.png'),
        },
        {
            name: "重置成功",
            time: "",
            img: require('@/assets/images/homeImg/flow-4.png'),
        }
    ];

    jumpToHome() {
        this.$router.push("/home");
    }

    identifyUrl = "";
    identifyUuid = 0;

    getUuid() {
        const s: any = [];
        const hexDigits = "0123456789abcdef";
        for (let i = 0; i < 32; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23];
        const uuid = s.join("");
        return uuid;
    }

    mounted() {
        this.handleToggleCode();
    }

    handleToggleCode() {
        this.identifyUuid = this.getUuid();
        this.identifyUrl = `${baseUrl}/mcaptcha.jpg?uuid=${this.identifyUuid}`;
    }

    // 点击立即重置
    async handleClickReset() {
        if (!this.username) {
            this.usernameTip = "请输入登录时使用的手机号或账户名";
            return;
        } else {
            this.usernameTip = "";
        }
        if (!this.identify) {
            this.identifyTip = "请输入图形验证码";
            return;
        } else {
            this.identifyTip = "";
        }
        let r: any = await request(`${baseUrl}/api/v1/exist/${this.username}`);
        if (!r.exist) {
            this.usernameTip = `用户名不存在或者手机号未注册`;
            return;
        }
        clickReset({
            userMobile: this.username,
            captcha: this.identify,
            uuid: this.identifyUuid,
        }).then((res: any) => {
            if (res && res.code === 0) {
                if (r.isname) {
                    request(`${baseUrl}/api/v1/getmobile/${this.username}`).then(
                        (res: any) => {
                            this.username = res["mobile"];
                        }
                    );
                }
                this.step = 2;
            } else {
                this.identifyTip = res.msg;
            }
        });
        // }
    }

    isSending = false;
    remainTime = 60;
    buttonContent = "获取短信验证码";

    handleSendCode() {
        this.remainTime = 60;

        getVerificationCode(this.username).then((res) => {
            if (res && res.code === 0) {
                message.success("验证码已发送！");
                this.isSending = true;
                this.vcodeTip = "验证码已发送，5分钟内输入有效";
                const t = setInterval(() => {
                    if (this.remainTime > 0) {
                        this.remainTime -= 1;
                        this.buttonContent = `${this.remainTime}s后重新获取`;
                    } else {
                        this.isSending = false;
                        this.buttonContent = "重新发送";
                        clearInterval(t);
                    }
                }, 1000);
            } else {
                message.error(res.msg);
            }
        });
    }

    vcodeTip = "";
    vcode = "";

    handleClickNext() {
        if (!this.vcode) {
            this.vcodeTip = "请输入短信验证码";
            return;
        }
        // this.step = 3;
        request(`${baseUrl}/api/v1/checkcode/${this.username}/${this.vcode}`).then(
            (res: any) => {
                if (res.check) {
                    this.step = 3;
                } else {
                    this.vcodeTip = "验证码错误或已失效，请重新获取";
                }
            }
        );
    }

    psw = "";
    pswTip = "";
    pswAgain = "";
    pswAgainTip = "";

    pwTwst() {
        const value = this.psw;
        if (value) {
            if (value.length > 20 || value.length < 8) {
                this.pswTip = "密码长度8-20个字符（密码必须包括数字和英文字母）";
                return;
            }
            let regex = /^(?=.*[a-z])(?=.*\d)[\s\S]{8,20}$/;
            if (!regex.test(value)) {
                this.pswTip = "密码必须包括数字和英文字母";
            }
        } else {
            this.pswTip = "请输入新的登录密码";
        }
    }

    handleClickSubmit() {
        if (!this.psw) {
            this.pswTip = "请输入新的登录密码";
            return;
        } else {
            this.pswTip = "";
        }
        if (!this.psw) {
            this.pswTip = "请再次输入新的登录密码";
            return;
        } else {
            this.pswTip = "";
        }
        if (this.psw != this.pswAgain) {
            this.pswAgainTip = "两次输入密码不一致，请重新输入";
            return;
        }
        userRepassword({
            phone_number: this.username,
            verification_code: this.vcode,
            new_password: this.psw,

        }).then((res: any) => {
            if (res && res.code === 200) {
                this.step = 4;
            } else if (res && res.code === 405) {
                this.vcode = '';
                message.error("验证码失效，请从新获取。");
                this.step = 2;
            } else {
                message.error(res.msg);
            }
        });
    }

    handleLogin(event) {
        event.stopPropagation();
        localStorage.setItem("loginBeforePath", "/forgetPassword");
        this.$router.push("/login");
    }
}
